async function updateQuantity(containerId, quantity) {
  const response = await fetch('/update-quantity.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      container_id: containerId,
      quantity: quantity,
    }),
  });
  return await response.json();
}

export default updateQuantity;
