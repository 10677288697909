async function addBeer(untappdId) {
  const response = await fetch('/add-beer.php', {
    method: 'POST',
    body: JSON.stringify({
      untappd_id: untappdId,
    }),
  });
  return await response.json();
}

export default addBeer;
