async function deleteBeer(itemId) {
  const response = await fetch('/delete-beer.php', {
    method: 'POST',
    body: JSON.stringify({
      item_id: itemId,
    }),
  });
  return await response.json();
}

export default deleteBeer;
