import React from 'react';
import logo from './logo.png';
import deleteIcon from './delete.svg';
import './App.css';
import addBeer from './addBeer';
import deleteBeer from './deleteBeer';
import fetchGraphQL from './fetchGraphQL';
import fetchInventory from './fetchInventory';
import updateQuantity from './updateQuantity';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const { useState, useEffect } = React;

const CONTAINER_IDS = ['322', '1256', '321'];

function App() {
  const [section, setSection] = useState(null);
  const [inventory, setInventory] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);

  function toggleEdit() {
    setEdit(!isEdit);
  }

  function generatePDF() {
    const url = '/generate-menu.php';
    window.open(url, '_blank');
  }

  function updateContainerQuantity(containerId, quantity) {
    inventory[containerId] = quantity;
    updateQuantity(containerId, quantity);
  }

  function handleReload() {
    setShouldReload(!shouldReload);
  }

  useEffect(() => {
    let isMounted = true;
    fetchGraphQL(`
      query KegSectionQuery {
        section(id:${process.env.REACT_APP_UTFB_SECTION_ID}) {
          name
          item_collection {
            id
            untappd_id
            name
            style
            abv
            ibu
            container_collection {
              container_size {
                id
                name
              }
              id
              price
            }
          }
        }
      }
    `)
      .then(response => {
        if (!isMounted) {
          return;
        }
        setSection(response.data.section);
      })
      .catch(error => {
        console.error(error);
      });

    return () => {
      isMounted = false;
    };
  }, [fetchGraphQL, shouldReload]);

  useEffect(() => {
    let isMounted = true;
    fetchInventory()
      .then(response => {
        if (!isMounted) {
          return;
        }
        setInventory(response);
      })
      .catch(error => {
        console.error(error);
        setInventory({});
      });

    return () => {
      isMounted = false;
    };
  }, [fetchInventory, shouldReload]);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <article>
        {section != null && inventory != null ? (
          <Section
            items={section.item_collection}
            inventory={inventory}
            isEdit={isEdit}
            onToggleEdit={toggleEdit}
            onGenerate={generatePDF}
            onUpdateContainerQuantity={updateContainerQuantity}
            onReload={handleReload}
          />
        ) : (
          'Loading...'
        )}
      </article>
    </div>
  );
}

function Section({
  items,
  inventory,
  isEdit,
  onToggleEdit,
  onGenerate,
  onUpdateContainerQuantity,
  onReload,
}) {
  return (
    <div>
      <h1>Keg Inventory</h1>
      {isEdit ? (
        <button onClick={onToggleEdit} title="Exits edit mode">
          Done Editing
        </button>
      ) : (
        <>
          <button
            onClick={onToggleEdit}
            title="Switches to edit mode to update keg inventory"
          >
            Edit
          </button>
          <button
            onClick={onGenerate}
            title="Generates a PDF menu by filtering out zero-quantity items and containers"
          >
            Generate Menu PDF
          </button>
        </>
      )}
      {isEdit ? (
        <AddBeerControl
          existingUntappdIds={items.map(i => i.untappd_id)}
          onReload={onReload}
        />
      ) : null}
      <div className="Section-items">
        <SectionHeader items={items} />
        {items.map(item => (
          <Item
            key={item.id}
            item={item}
            isEdit={isEdit}
            inventory={inventory}
            onUpdateContainerQuantity={onUpdateContainerQuantity}
            onReload={onReload}
          />
        ))}
      </div>
    </div>
  );
}

function AddBeerControl({ existingUntappdIds, onReload }) {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  function handleSearch(query) {
    setIsLoading(true);
    fetchGraphQL(`
      query FindBeer {
        search_untappd_items(query:"norbrook ${query}") {
          untappd_id
          name
          untappd_brewery_id
        }
      }
    `)
      .then(response => {
        setIsLoading(false);
        setOptions(
          response.data.search_untappd_items.filter(
            item =>
              existingUntappdIds.indexOf(item.untappd_id) === -1 &&
              item.untappd_brewery_id + '' ===
                process.env.REACT_APP_UTFB_BREWERY_ID
          )
        );
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error);
      });
  }

  function handleChange(items) {
    if (items.length === 1) {
      setIsAdding(true);
      addBeer(items[0].untappd_id)
        .then(() => {
          setIsAdding(false);
          setSelected([]);
          onReload();
        })
        .catch(error => {
          setIsAdding(false);
          setSelected([]);
          console.error(error);
        });
    }
  }

  return (
    <div className="App-addBeer">
      {isAdding ? (
        <div>Adding Beer...</div>
      ) : (
        <AsyncTypeahead
          labelKey="name"
          options={options}
          minLength={1}
          onChange={handleChange}
          onSearch={handleSearch}
          selected={selected}
          placeholder="Add a beer..."
        />
      )}
    </div>
  );
}

function SectionHeader({ items }) {
  const containerNameMap = items.reduce((result, item) => {
    item.container_collection.forEach(container => {
      if (!(container.container_size.id in result)) {
        result[container.container_size.id] = container.container_size.name;
      }
    });
    return result;
  }, {});

  return (
    <Row className="Section-header">
      <Cell primary>Beer</Cell>
      {CONTAINER_IDS.map(containerId => (
        <Cell key={containerId}>{containerNameMap[containerId]}</Cell>
      ))}
    </Row>
  );
}

function Item({
  item,
  isEdit,
  inventory,
  onUpdateContainerQuantity,
  onReload,
}) {
  const [isHovering, setIsHovering] = useState(false);

  function handleMouseEnter() {
    setIsHovering(true);
  }

  function handleMouseLeave() {
    setIsHovering(false);
  }

  return (
    <Row
      className="Item-root"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Cell primary>
        <div className="Item-info">
          <div className="Item-name">{item.name}</div>
          <div className="Item-meta">
            {item.style} / {item.abv}% ABV / {item.ibu} IBU
          </div>
        </div>
      </Cell>
      {CONTAINER_IDS.map(containerId => (
        <Cell key={containerId}>
          <ContainerPrice
            id={containerId}
            containers={item.container_collection}
          />
          <ContainerQuantity
            id={containerId}
            containers={item.container_collection}
            isEdit={isEdit}
            inventory={inventory}
            onUpdateContainerQuantity={onUpdateContainerQuantity}
          />
        </Cell>
      ))}
      {isEdit && isHovering ? (
        <EditActions item={item} onReload={onReload} />
      ) : null}
    </Row>
  );
}

function EditActions({ item, onReload }) {
  const [isDeleting, setIsDeleting] = useState(false);

  function handleDelete() {
    setIsDeleting(true);
    deleteBeer(item.id)
      .then(() => {
        setIsDeleting(false);
        onReload();
      })
      .catch(error => {
        setIsDeleting(false);
        console.error(error);
      });
  }

  return (
    <div className="Item-edit">
      <button
        className="Item-delete"
        title="Delete Beer"
        onClick={handleDelete}
        disabled={isDeleting}
      >
        {isDeleting ? (
          <>
            {' '}
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Loading...</span>
          </>
        ) : (
          <img src={deleteIcon} alt="Delete" />
        )}
      </button>
    </div>
  );
}

function ContainerPrice({ id, containers }) {
  const matchingContainer = containers.find(c => c.container_size.id === id);
  if (matchingContainer != null) {
    return <div className="price">${matchingContainer.price}</div>;
  }
  return <div className="price">n/a</div>;
}

function ContainerQuantity({
  id,
  containers,
  isEdit,
  inventory,
  onUpdateContainerQuantity,
}) {
  const matchingContainer = containers.find(c => c.container_size.id === id);
  if (matchingContainer != null) {
    const containerQuantity = inventory[matchingContainer.id] ?? 0;

    return (
      <div className="quantity">
        {isEdit ? (
          <ContainerQuantitySelector
            quantity={containerQuantity}
            onUpdateQuantity={quantity =>
              onUpdateContainerQuantity(matchingContainer.id, quantity)
            }
          />
        ) : (
          <ContainerQuantityDisplay quantity={containerQuantity} />
        )}
      </div>
    );
  }
  return <div className="quantity">n/a</div>;
}

function ContainerQuantityDisplay({ quantity }) {
  return <div className="quantity-display">{quantity}</div>;
}

function ContainerQuantitySelector({ quantity, onUpdateQuantity }) {
  const [selectedQuantity, setSelectedQuantity] = useState(quantity);
  return (
    <select
      value={selectedQuantity}
      onChange={e => {
        setSelectedQuantity(e.target.value);
        onUpdateQuantity(e.target.value);
      }}
    >
      {[...Array(51).keys()].map(i => (
        <option key={i}>{i}</option>
      ))}
    </select>
  );
}

function Row({ children, className, onMouseEnter, onMouseLeave }) {
  return (
    <div
      className={`row ${className}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
}

function Cell({ children, primary }) {
  const className = primary ? 'cell primary-cell' : 'cell';
  return <div className={className}>{children}</div>;
}

export default App;
