async function fetchGraphQL(text, variables) {
  const AUTH_TOKEN = process.env.REACT_APP_UTFB_AUTH_TOKEN;
  const TOKEN = btoa(`beer@norbrookfarm.com:${AUTH_TOKEN}`);

  const response = await fetch('https://business.untappd.com/graphql', {
    method: 'POST',
    headers: {
      Authorization: `Basic ${TOKEN}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: text,
      variables,
    }),
  });
  return await response.json();
}

export default fetchGraphQL;
